import React, { useRef, useState } from 'react';
import { usePopper } from 'react-popper';

import useOutsideClick from '../../hooks/useOutsideClick';

import styles from './index.module.scss';

const ImageTooltip = ({ image, description = '' }) => {
  const [referenceElement, setReferenceElement] = useState(null);
  const [popperElement, setPopperElement] = useState(null);
  const [isVisible, setIsVisible] = useState(false);
  const wrapperRef = useRef(null);
  const { styles: popperStyles, attributes, update } = usePopper(referenceElement, popperElement, {
    placement: 'bottom-start',
    modifiers: [
      {
        name: 'offset',
        options: {
          offset: [0, 15],
        },
      },
    ],
  });

  const handleClick = () => {
    update();
    setIsVisible((prev) => !prev);
  };

  useOutsideClick(wrapperRef, () => setIsVisible(false));

  return (
    <div ref={wrapperRef}>
      <svg
        ref={setReferenceElement}
        onClick={handleClick}
        className="ml-auto cursor-pointer"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        color={isVisible ? '#2684FF' : '#2B2B2B66'}
      >
        <path d="M19.999 4H3.99902C2.89602 4 1.99902 4.897 1.99902 6V18C1.99902 19.103 2.89602 20 3.99902 20H19.999C21.102 20 21.999 19.103 21.999 18V6C21.999 4.897 21.102 4 19.999 4ZM6.49902 7C6.89685 7 7.27838 7.15804 7.55968 7.43934C7.84099 7.72064 7.99902 8.10218 7.99902 8.5C7.99902 8.89782 7.84099 9.27936 7.55968 9.56066C7.27838 9.84196 6.89685 10 6.49902 10C6.1012 10 5.71967 9.84196 5.43836 9.56066C5.15706 9.27936 4.99902 8.89782 4.99902 8.5C4.99902 8.10218 5.15706 7.72064 5.43836 7.43934C5.71967 7.15804 6.1012 7 6.49902 7ZM11.999 17H4.99902L8.99902 12L10.499 14L13.499 10L18.999 17H11.999Z" fill="currentColor" />
      </svg>
      <div
        ref={setPopperElement}
        style={{
          ...popperStyles.popper,
          opacity: isVisible ? 1 : 0,
          zIndex: isVisible ? '2' : '-999',
        }}
        {...attributes.popper}
        className={styles.content}
      >
        <img
          src={image}
          alt="First one"
          className={styles.image}
        />
        {description && <div className={styles.description}>{description}</div>}
      </div>
    </div>
  );
};

export default ImageTooltip;
