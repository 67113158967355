
export const DOCUMENT_TYPES = {
    reviewPack: 'REVIEW_PACK',
    itineraryPack: 'ITINERARY_PACK',
    itineraryExtract: 'ITINERARY_EXTRACT',
    reviewExtract: 'REVIEW_EXTRACT'
};

export const COMPANY_TYPES = {
    b2b: "B2B",
    b2b2c: "B2B2C"
};
