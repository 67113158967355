import React, { useState, useCallback, useEffect } from 'react';

import SwiperCore, {
  Navigation, Pagination, Parallax, Autoplay, Keyboard, EffectFade,
} from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import Modal from '../UI/Modal/Modal';

// Import Swiper styles
import 'swiper/swiper-bundle.min.css';
import 'swiper/swiper.min.css';

import styles from './Slideshow.module.scss';

const OFFSET = 500;
const DELAY = 2500 + OFFSET;

SwiperCore.use([Navigation, Pagination, Autoplay, Keyboard, Parallax, EffectFade]);

function Slideshow({
  isOpen = false,
  slides = [],
  onCloseClick = () => {},
}) {
  const [isPlay, setIsPlay] = useState(true);
  const [swiperState, setSwiperState] = useState(null);
  const bar = document.getElementById('bar');
  const btn = document.getElementById('play-control-btn');
  let totalLength = 0;
  const [progress, setProgress] = useState(0);
  // const [currentTransitionSpeed, setCurrentTransitionSpeed] = useState(0)

  if (bar) {
    totalLength = bar.getTotalLength();
  }

  const play = useCallback((event) => {
    setIsPlay(true);
    btn.classList.add('playing');
    event.preventDefault();
    swiperState.autoplay.start();
  }, [swiperState, setIsPlay]);

  const pause = useCallback((event) => {
    setIsPlay(false);
    btn.classList.remove('playing');
    event.preventDefault();
    swiperState.autoplay.stop();
  }, [swiperState, setIsPlay]);

  useEffect(() => {
    if (!isPlay) {
      setProgress(0);
      return;
    }
    const dateNow = Date.now();
    let timeout;

    if (progress < 100) {
      timeout = setTimeout(() => {
        const offsetX = Date.now() - dateNow;
        const offset = (offsetX * 100) / (2000 + OFFSET);
        const currentTime = progress + offset;
        setProgress(Math.min(currentTime, 100));
      }, 40);
    }
    return () => {
      clearTimeout(timeout);
    };
  }, [isPlay, progress]);

  // const getTransitionSpeed = () => {
  //   const transitionSpeed = currentTransitionSpeed;
  //   setCurrentTransitionSpeed(0);
  //   return transitionSpeed;
  // }

  // const getActiveIndexBeforeTransitionStart = (swiper, slides) => {
  //   const isDragging = !Math.abs(slides[swiper.activeIndex].progress === 1);
  //   if (isDragging) {
  //     return swiper.slidesGrid.indexOf(
  //       -swiper.touchEventsData.startTranslate || swiper.params.initialSlide
  //     );
  //   } else {
  //     return swiper.activeIndex;
  //   }
  // }

  // const getDirection = (animationProgress) => {
  //   if (animationProgress === 0) {
  //     return "NONE";
  //   } else if (animationProgress > 0) {
  //     return "NEXT";
  //   } else {
  //     return "BACK";
  //   }
  // }

  // const progress = (swiper, progress) => {
  // }

  // const setTransition = (swiper, transitionSpeed) => {
  //   setCurrentTransitionSpeed(transitionSpeed);
  //   // console.log("transition", transitionSpeed);
  // }

  // const setTranslate = (swiper, wrapperTranslate) => {
  //   const durationInSeconds = getTransitionSpeed() / 1000;

  //   const slides = Object.values(swiper.slides).slice(0, -1);
  //   console.log("setTranslate slides", slides)

  //   const originIndex = getActiveIndexBeforeTransitionStart(
  //     swiper,
  //     slides
  //   );

  //   const animationProgress = slides[originIndex].progress;

  //   const direction = getDirection(animationProgress);
  //   // console.log(direction);

  //   // do magic with each slide
  //   slides.map((slide) => {
  //     const offset = slide.swiperSlideOffset;
  //     let x = -offset;
  //     if (!swiper.params.virtualTranslate) x -= swiper.translate;
  //     let y = 0;
  //     if (!swiper.isHorizontal()) {
  //       y = x;
  //       x = 0;
  //     }
  //     gsap.set(slide, {
  //       x,
  //       y
  //     });

  //     const clip = (val, min, max) => Math.max(min, Math.min(val, max));
  //     const ZOOM_FACTOR = 0.05;

  //     const opacity = Math.max(1 - Math.abs(slide.progress), 0);

  //     const clippedProgress = clip(slide.progress, -1, 1);
  //     const scale = 1 - ZOOM_FACTOR * clippedProgress;

  //     gsap.to(slide, {
  //       duration: durationInSeconds,
  //       x: scale,
  //       y: opacity
  //     });
  //   });
  // }

  return (
    <Modal
      showClose
      displayToggle={onCloseClick}
      show={isOpen}
      modalClasses={`${styles.modal} col-8`}
      closeStyles={styles.modalClose}
    >
      {isOpen && (
      <Swiper
        navigation
        autoHeight
        grabCursor
        autoplay={{
          delay: DELAY,
          disableOnInteraction: false,
        }}
        keyboard={{
          enabled: true,
        }}
        slidesPerView={1}
        spaceBetween={5}
        centeredSlides
        speed={600 + OFFSET}
        parallax
                // observer={true}
                // observeParents={true}
        pagination={{
          clickable: true,
        }}
        modules={[Parallax, Pagination, Navigation, Autoplay, Keyboard, EffectFade]}
        loop={false}
        onInit={(swiper) => {
          setSwiperState(swiper);
        }}
        onSlideChange={() => {
          setProgress(0);
        }}
        className={`${styles.swiperContainer} ${isPlay ? 'hideNav' : 'showHav'}`}
        style={{
          '--swiper-navigation-color': '#fff',
          '--swiper-pagination-color': '#fff',
        }}
        effect="fade"
                // effect="myCustomTransition"
        id="slideshow"
      >
        {slides.map((activity, index) => (
          <SwiperSlide
            key={`${index}`}
            className={styles.swiperSlides}
          >
            <div className={`parallax-bg ${styles.wrapper}`}>
              <img
                className={styles.image}
                alt="activity"
                src={activity.firstPhoto.file.link}
              />
              <div className={styles.infoBlock}>
                <div className={styles.title}>{activity.title}</div>
                <div className={styles.actionBlock}>
                  <div className={styles.description}>{activity.introductionDescription}</div>
                  <div className={styles.action}>
                    <svg id="play-control-btn" className="playing" width="63" height="63" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" viewBox="0 0 100 100">
                      <path id="border" fill="none" stroke="#fff" strokeWidth="1.5" strokeMiterlimit="10" d="M50,2.9L50,2.9C76,2.9,97.1,24,97.1,50v0C97.1,76,76,97.1,50,97.1h0C24,97.1,2.9,76,2.9,50v0C2.9,24,24,2.9,50,2.9z" />
                      <path id="bar" fill="none" strokeDasharray={totalLength} strokeDashoffset={totalLength - ((totalLength * progress) / 100)} stroke="#fff" strokeWidth="4.5" strokeMiterlimit="10" d="M50,2.9L50,2.9C76,2.9,97.1,24,97.1,50v0C97.1,76,76,97.1,50,97.1h0C24,97.1,2.9,76,2.9,50v0C2.9,24,24,2.9,50,2.9z" style={{ transition: 'all .1s' }} />
                      {isPlay ? (
                        <g id="pause" onClick={(event) => pause(event)}>
                          <g>
                            <path fill="#fff" d="M46.1,65.7h-7.3c-0.4,0-0.7-0.3-0.7-0.7V35c0-0.4,0.3-0.7,0.7-0.7h7.3c0.4,0,0.7,0.3,0.7,0.7V65 C46.8,65.4,46.5,65.7,46.1,65.7z" />
                            <path fill="#fff" d="M61.2,65.7h-7.3c-0.4,0-0.7-0.3-0.7-0.7V35c0-0.4,0.3-0.7,0.7-0.7h7.3c0.4,0,0.7,0.3,0.7,0.7V65 C61.9,65.4,61.6,65.7,61.2,65.7z" />
                          </g>
                        </g>
                      )
                        : (
                          <g id="play" onClick={(event) => play(event)}>
                            <path fill="#fff" d="M41.1,33.6l24.5,15.6c0.6,0.4,0.6,1.1,0,1.5L41.1,66.4c-0.7,0.5-1.8,0-1.8-0.7V34.4 C39.3,33.6,40.4,33.2,41.1,33.6z" />
                          </g>
                        )}
                    </svg>
                  </div>
                </div>
              </div>
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
      )}
    </Modal>
  );
}

export default Slideshow;
