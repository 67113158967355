
import http from './http.service';

const DocumentPdfService = () => {
  const basePathReview = '/search/activities/';
  const basePathItineraries = '/search/itineraries/'

  const getReviewPackAmountAndSecretKey = (ids, partnerNickname) => http.get(`${basePathReview}pdf/price`, {params: { ids, partnerNickname }});

  const getItineraryPackAmountAndSecretKey = (uuid, partnerNickname) => http.get(`${basePathItineraries}pdf/price`, {params: { uuid, partnerNickname }});


  return {
    getReviewPackAmountAndSecretKey,
    getItineraryPackAmountAndSecretKey
  };
};

export default DocumentPdfService;