import React from 'react';

import CommonCard from './Common';
import styles from './index.module.scss';

const LeaveAccomodationCard = ({
  isShortDuration,
  isChange,
  title,
  size,
  leaveTime,
}) => (
  <CommonCard
    className={styles.cardAccomodation}
    isShortDuration={isShortDuration}
    size={size}
  >
    {isChange && (
      <div className={`${styles.cardAccomodationName}`}>
        Changing accommodation today, please pack
      </div>
    )}
    <div className={`${styles.cardAccomodationName} my-auto`}>
      {title}
    </div>
    <div className={`${styles.cardTime} mb-0`}>{leaveTime}</div>
  </CommonCard>
);

export default LeaveAccomodationCard;
