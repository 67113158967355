import React from 'react';
import { formatCurrency } from '../../helpers/helper-methods';
import ImageTooltip from '../ImageTooltip';

import CommonCard from './Common';
import styles from './index.module.scss';

const minActivitySize = 3;

const ActivityCard = ({
  activity,
  size,
  duration,
  startTime,
  isShortDuration,
  currency,
}) => (
  <CommonCard
    className={styles.cardActivity}
    isShortDuration={isShortDuration}
    size={size}
  >
    {size < minActivitySize
      ? (
        <>
          <div className={`${styles.cardTime} flex`} data-is-short="true">
            <span>
              {`${startTime?.substr(0, 5)} / ${duration}`}
            </span>
            <span className={`ml-auto ${styles.cardPrice}`}>
              {activity?.price ? formatCurrency(currency, activity?.price) : 'Free'}
            </span>
          </div>
          <div className="flex flex-between">
            <a
              data-is-short="true"
              target="_blank"
              rel="noreferrer noopener"
              className={styles.cardTitle}
              href={`/activities?id=${activity?.id}`}
            >
              {activity?.title}
            </a>
            {activity?.firstPhoto?.file?.link && (
              <ImageTooltip image={activity?.firstPhoto?.file?.link} description={activity?.firstPhoto?.description} />
            )}
          </div>
        </>
      )
      : (
        <>
          <div className="flex flex-between">
            <div className={styles.cardTime}>
              {startTime?.substr(0, 5)}
            </div>
            {activity?.firstPhoto?.file?.link && (
              <ImageTooltip image={activity?.firstPhoto?.file?.link} description={activity?.firstPhoto?.description}/>
            )}
          </div>
          <a
            target="_blank"
            rel="noreferrer noopener"
            className={styles.cardTitle}
            href={`/activities?id=${activity?.id}`}
          >
            {activity?.title}
          </a>
          <div className="flex flex-v-center flex-between mt-auto">
            {duration && <div className={`${styles.cardTime} mb-0`}>{duration}</div>}
            <div className={styles.cardPrice}>{activity?.price ? formatCurrency(currency, activity?.price) : 'Free'}</div>
          </div>
        </>
      )}
  </CommonCard>
);

export default ActivityCard;
