import React from 'react';
import styles from './index.module.scss';

const normalCardSize = 4;

const CommonCard = ({
  children,
  className,
  size,
  isShortDuration,
  offset,
}) => (
  <div
    className={`${styles.card} ${className} ${size === 'auto' && styles.cardFull}`}
    data-is-short={isShortDuration}
    style={{
      '--size': size,
      '--clamp': size < normalCardSize ? 1 : 3,
      '--offset': offset,
    }}
  >
    {children}
  </div>
);

export default CommonCard;
