import React, { useCallback } from "react";
import { Formik, Field, Form } from 'formik';
import * as Yup from 'yup';

import { noop } from "../../../helpers/helper-methods";
import FormikRadioGroup from '../../RadioGroup/RadioGroup';
import { Input, Textarea, CustomPhoneInput } from "../Input/Input";
import InputWrapper from '../InputWrapper/InputWrapper';
import Label from '../Label/Label';
import Button from '../Button/Button';
import SpinnerIcon from '../../../assets/img/icons/ic-spinner-colorable.svg';
import { EMAIL_MAX, PHONE_MIN, PROP_DESCR_MAX } from "../../../constans/validation";
import { EMAIL_INVALID, EMAIL_REQUIRED, PHONE_INVALID } from "../../../constans/error-messages";
import Modal from "../Modal/Modal";

import styles from "./ModalSavePackAgent.module.scss";

export const deliveryVariants = {
    EMAIL: 'EMAIL',
    DEVICE: 'DEVICE'
}

const deliveryOptionsItineraries = [
    {
        label: 'Send to email',
        value: deliveryVariants.EMAIL,
    },
    {
        label: 'Download on device',
        value: deliveryVariants.DEVICE,
    },
];

const deliveryOptionsReviews = [
    {
        label: 'Send to email',
        value: deliveryVariants.EMAIL,
    },
    {
        label: 'Download on device',
        value: deliveryVariants.DEVICE,
    },
];

const buyPackSchema = Yup.object({
    deliveryOption: Yup.string(),
    email: Yup.string().when('deliveryOption', {
        is: deliveryVariants.EMAIL,
        then: Yup.string().email(EMAIL_INVALID).max(EMAIL_MAX).required(EMAIL_REQUIRED)
      }),
      agentEmail: Yup.string().email(EMAIL_INVALID).max(EMAIL_MAX),
      agentPhone: Yup.string().min(PHONE_MIN, PHONE_INVALID),
      notes: Yup.string().max(PROP_DESCR_MAX),
  });

const ModalSavePackAgent = ({
    isShow,
    toggleModal = noop,
    title = '',
    onDownloadPdf = noop,
    locationInfo = '',
    isDownloading = false,
    isItineraries = true,
    // amount
}) => {
    const handleSubmit = useCallback((values) => {
        onDownloadPdf(
            values.email, 
            {
                agentEmail: values.agentEmail, 
                ...values.agentPhone && {
                    agentPhone: `+${values.agentPhone.replace(/\+/, '')}`
                },
                notes: values.notes
            },
            values.deliveryOption
            );
    }, [onDownloadPdf])

    return(
        <Modal
            show={isShow}
            displayToggle={toggleModal}
            modalClasses={`${styles.modal}`}  
        >
            <div>
            <p className={`${styles.title}`}>{title}</p>
            <Formik 
                onSubmit={handleSubmit} 
                initialValues={{
                    deliveryOption: deliveryVariants.EMAIL,
                    email: ''
                }}
                validationSchema={buyPackSchema}
            >
                {
                    ({values, isValid, touched}) => {
                        const isEmail = values.deliveryOption === deliveryVariants.EMAIL;
                        const isDevice = values.deliveryOption === deliveryVariants.DEVICE
                        return(
                            <Form>
                                <div className={`${styles.sectionSubtitle} uppercased-text`}>
                                    Delivery option
                                </div>
                                <Field
                                    name="deliveryOption"
                                    wrapperStyles={styles.radios}
                                    labelClassName={`col-sm-4 p-0 ${styles.radioLabel}`}
                                    options={isItineraries ? deliveryOptionsItineraries : deliveryOptionsReviews}
                                    component={FormikRadioGroup}
                                />
                                <InputWrapper containerStyles={styles.inputBlock}>
                                    <Label containerStyles={`${isDevice && styles.disabled}`}>
                                        Email address 
                                        {isEmail && <span className={`${styles.required}`}>*</span>}
                                    </Label>
                                    <Input
                                        type="email"
                                        name={'email'}
                                        disabled={isDevice}
                                        placeholder={'Enter email'}
                                        maxLength={EMAIL_MAX}
                                    />
                                </InputWrapper>
                                <InputWrapper containerStyles={styles.inputBlock}>
                                    <Label>
                                        Agent email 
                                    </Label>
                                    <Input
                                        type="email"
                                        name={'agentEmail'}
                                        placeholder={'Enter agent email'}
                                        maxLength={EMAIL_MAX}
                                    />
                                </InputWrapper>
                                <InputWrapper containerStyles={styles.inputBlock}>
                                    <Label>
                                        Agent phone 
                                    </Label>
                                    <CustomPhoneInput
                                        name={'agentPhone'}
                                        placeholder={'Enter agent phone'}
                                    />
                                </InputWrapper>
                                <InputWrapper containerStyles={styles.inputBlock}>
                                    <Label>
                                        Notes
                                    </Label>
                                    <Textarea
                                        type="text"
                                        maxLength={PROP_DESCR_MAX}
                                        name="notes"
                                        rows={3}
                                        placeholder="Enter notes"
                                    />
                                </InputWrapper>
                                <div className={`${styles.subTitle}`}>
                                    <p className={`${styles.location}`}>{locationInfo}</p>
                                </div>
                                {/* <p className={`${styles.title} ${styles.topBorder}`}>{`$`}{amount}</p> */}
                                <Button
                                    classes={`t-600 btn btn-uppercased btn_common ${styles.button}`}
                                    type="submit"  
                                    disabled={!(isValid && touched) || isDownloading}      
                                >
                                    {'Send / Download'}
                                </Button>
                                {isDownloading && values.deliveryOption === deliveryVariants.DEVICE && <div className={`${styles.loadingWrapper} flex flex-v-center t-500`}>
                                    <SpinnerIcon className={styles.iconSpinner} />
                                    <span>Generating pdf...</span>
                                </div>}
                            </Form>
                        )
                    }
                }  
            </Formik>
        </div>
        </Modal>
    )
}

export default ModalSavePackAgent;