import { useCallback, useState } from 'react';
import toastr from 'toastr';
import { deliveryVariants } from '../components/UI/ModalSavePackAgent/ModalSavePackAgent';

import itinerarySearchService from '../services/itinerarySearchService';

const useDownloadItinerariesPdf = () => {
  const [downloadPdfLoading, setDownloadPdfLoading] = useState(false);
  const [link, setLink] = useState('')

  const downloadItinerariesPdf = useCallback(({
    uuid,
    locationName,
    currency,
    email, 
    partnerNickname, 
    deliveryOption,
    isScheduleOnly
  }, {
      agentEmail, 
      agentPhone, 
      notes
    }
  ) => {
    setDownloadPdfLoading(true);
    itinerarySearchService()
      .generatePdfPack({
        uuid,
        locationName,
        currency,
        ...email && {
          email
        },
        partnerNickname,
        deliveryOption,
        isScheduleOnly
      }, {
        agentEmail, 
        agentPhone, 
        notes
      })
      .then(({ data }) => {
        toastr.success('Generating document successfully started');
        if (deliveryOption === deliveryVariants.DEVICE) {
          setLink(data.link)
        }
      })
      .catch((err) => {
        // todo: show toast?
        console.warn(err);
      })
      .finally(() => {
        setDownloadPdfLoading(false);
      });
  });

  return { downloadItinerariesPdf, downloadPdfLoading, link };
};

export default useDownloadItinerariesPdf;
