import React, { useMemo, useState } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import {
  Elements,
  useStripe,
  useElements,
  PaymentElement,
} from '@stripe/react-stripe-js';
import { navigate } from 'gatsby';

import Modal from '../UI/Modal/Modal';
import Button from '../UI/Button/Button';
import { DOCUMENT_TYPES } from '../../constans/pdf-documents';

import styles from './StripePaymentPdfModal.module.scss';

function CheckoutForm({
  show,
  displayToggle,
  stripeSubmitCallback,
  amount,
  locationName,
  type,
  uuid,
  currency,
  totalCount,
  locationInfo,
  user,
}) {
  const [loading, setLoading] = useState(false);
  const [stripeError, setStripeError] = useState('');
  const stripe = useStripe();
  const elements = useElements();

  const handleSubmit = async (event) => {
    try {
      setLoading(true);
      // We don't want to let default form submission happen here,
      // which would refresh the page.
      event.preventDefault();

      if (!stripe || !elements) {
        // Stripe.js has not yet loaded.
        // Make  sure to disable form submission until Stripe.js has loaded.
        return;
      }

      const returnUrl = `https://${process.env.HOSTNAME}/check-out-success`;

      stripe.confirmPayment({
        // `Elements` instance that was used to create the Payment Element http://localhost:8000 (dev work) https://${process.env.HOSTNAME}
        elements,
        confirmParams: {
          return_url: returnUrl,
          receipt_email: user.email,
        },
        redirect: 'if_required',
      })
        .then((result) => {
          if (result?.paymentIntent) {
            stripeSubmitCallback(user.email);
            setLoading(false);
            navigate(returnUrl);
            return;
          }

          if (result?.error) {
            setStripeError(result?.error?.message);
            setLoading(false);
            return;
          }

          stripeSubmitCallback(user.email);
        })
        .finally(() => {
          setLoading(false);
        });
    } catch (e) {
      setLoading(false);
    }
  };

  const packName = useMemo(() => {
    const name = {
      [DOCUMENT_TYPES.reviewPack]: 'review pack',
      [DOCUMENT_TYPES.itineraryPack]: 'itinerary pack',
    };

    return name[type];
  }, [type]);

  return (
    <Modal
      closeStyles={loading ? styles.disabled : ''}
      modalClasses={styles.modal}
      displayToggle={displayToggle}
      show={show}
    >
      <>
        <div className={`${styles.subTitle}`}>
          <p className={`${styles.location}`}>{locationInfo}</p>
        </div>
        <div className="mb-1em" />
        <PaymentElement />
        {stripeError ? <div className="error-input-msg text-center mb-1em">{stripeError}</div> : null}
        <div className={`${styles.modalTitle} `}>
          <div className="h4-title">
            US$
            {amount}
          </div>
          <div className={`${styles.description} `}>Includes GST/VAT and Stripe fee</div>
        </div>

        <div className={styles.modalTitle}>
          A link to the generated itinerary pack will be sent to your registered email address
        </div>

        <div className={`${styles.submitFooter} flex flex-between`}>
          <Button
            disabled={loading}
            type="button"
            onClick={displayToggle}
            classes={`${styles.cancelBtn} ${styles.btn} btn flex flex-center btn-uppercased btn_brs-28 btn_padded btn-uppercased btn_bordered t-700`}
          >
            <span>
              Cancel
            </span>
          </Button>
          <Button
            classes={`${styles.submitBtn} ${styles.btn} btn btn_brs-28 btn-uppercased btn_common t-600`}
            type="submit"
            disabled={!stripe || loading}
            onClick={(event) => handleSubmit(event)}
          >
            <span>
              Proceed
            </span>
          </Button>
        </div>
      </>
    </Modal>
  );
}

const stripePromise = loadStripe(process.env.STRIPE_PUB_KEY);

function StripePaymentPdfModal({
  show,
  displayToggle,
  stripeSubmitCallback,
  clientSecret,
  amount,
  locationName,
  type,
  uuid,
  currency,
  totalCount,
  locationInfo,
  user,
}) {
  const options = {
    clientSecret,
  };

  return (
    <Elements stripe={stripePromise} options={options}>
      <CheckoutForm
        show={show}
        displayToggle={displayToggle}
        stripeSubmitCallback={stripeSubmitCallback}
        amount={amount}
        locationName={locationName}
        type={type}
        uuid={uuid}
        currency={currency}
        totalCount={totalCount}
        locationInfo={locationInfo}
        user={user}
      />
    </Elements>
  );
}

export default StripePaymentPdfModal;
