import React, { forwardRef } from 'react';

import NextPage from '../../assets/img/icons/ic-chevron-right-light-gray.svg';
import PrevPage from '../../assets/img/icons/ic-chevron-left-light-gray.svg';
import FirstPage from '../../assets/img/icons/ic-chevrons-left.svg';
import LastPage from '../../assets/img/icons/ic-chevrons-right.svg';

import styles from './index.module.scss';

const ItineraryPagination = forwardRef(({
  setActivePage,
  activePage,
  totalPages,
  isBottom,
  children,
  className,
}, ref) => (
  <div className={`${styles.controllers} ${isBottom && styles.bottom} ${className}`} ref={ref}>
    <button
      disabled={activePage === 0}
      className={`btn ${styles.arrow} ${styles.arrowEnd}`}
      onClick={() => setActivePage(0)}
      type="button"
    >
      <FirstPage />
    </button>
    <button
      disabled={activePage === 0}
      className={`btn ${styles.arrow}`}
      onClick={() => setActivePage((prevActivePage) => prevActivePage - 1)}
      type="button"
    >
      <PrevPage />
    </button>
    {children}
    <button
      disabled={totalPages - 1 <= activePage}
      className={`btn ${styles.arrow}`}
      type="button"
      onClick={() => setActivePage((prevActivePage) => prevActivePage + 1)}
    >
      <NextPage />
    </button>
    <button
      disabled={totalPages - 1 <= activePage}
      className={`btn ${styles.arrow} ${styles.arrowEnd}`}
      type="button"
      onClick={() => setActivePage(totalPages - 1)}
    >
      <LastPage />
    </button>
  </div>
));

export default ItineraryPagination;
