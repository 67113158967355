import React from 'react';
import CommonCard from './Common';
import styles from './index.module.scss';

const TransportCard = ({ size, duration, isShortDuration }) => (
  <CommonCard
    className={styles.cardTransport}
    isShortDuration={isShortDuration}
    size={size}
  >
    <div data-is-short={isShortDuration} className={styles.cardSubTitle}>Travel</div>
    <div className={`${styles.cardTime}`}>{duration}</div>
  </CommonCard>
);

export default TransportCard;
