export const ItineraryTypes = {
  Accomodation: 'ACCOMODATION',
  Transport: 'TRANSPORTATION',
  Activity: 'ACTIVITY',
  Lunch: 'LUNCH',
};

export const ScheduleDayType = {
  FULL: '( Full )',
  AFTERNOON: '( Afternoon )',
  MORNING: '( Morning )',
};
