import React from 'react';
import CommonCard from './Common';
import styles from './index.module.scss';

const AccomodationCard = ({
  isShortDuration,
  size,
  isChange,
  title,
  time,
}) => (
  <CommonCard
    className={styles.cardAccomodation}
    isShortDuration={isShortDuration}
    size={size}
  >
    <div className={`${styles.cardTime} mb-0`}>{time?.substr(0, 5)}</div>
    {isChange
      ? (
        <div className={`${styles.cardAccomodationName} ${styles.cardAccomodationChange}`}>
          {`Accommodation in ${title}`}
        </div>
      )
      : (
        <div className={`${styles.cardAccomodationName} ${styles.cardAccomodationChange}`}>
          No change in accommodation
        </div>
      )}
  </CommonCard>
);

export default AccomodationCard;
