/* eslint-disable no-param-reassign */
/* eslint-disable operator-assignment */
/* eslint-disable max-len */
import React, { Fragment } from 'react';

import ItineraryCard from '../ItineraryCard';
import { AccomodationCard, LeaveAccomodationCard } from '../ItineraryCards';
import { getDurationTime } from '../../helpers/helper-methods';
import { ItineraryTypes } from '../../constans/itineraries';

import styles from './index.module.scss';

const sectionDuration = 0.25;

const ItineraryColumn = ({
  cards = [],
  accommodationCities = [],
  colStartTime,
  lastActivityTime,
  currency,
}) => {
  const changeToday = accommodationCities[0]?.city?.id !== accommodationCities[1]?.city?.id;
  const leaveAccomodationSize = Math.round((getDurationTime(`${colStartTime}:00`, cards?.[0]?.startAt) * 60) / 15);

  if (!cards?.length) {
    return (
      <div className={`flex flex-col ${styles.column}`} />
    );
  }

  return (
    <div className={`flex flex-col ${styles.column}`}>
      <LeaveAccomodationCard
        size={leaveAccomodationSize}
        title={accommodationCities[0]?.city?.name}
        isChange={changeToday}
        leaveTime={cards?.[0]?.startAt?.substr(0, 5)}
      />
      {cards.map((card, index) => {
        const isLunch = card?.type === ItineraryTypes.Lunch;
        const nextCard = cards?.[index + 1];
        const prevCard = cards?.[index - 1];
        const currentDuration = card?.duration;
        const currentActivityEndTime = card?.endAt;
        const currentActivityStartTime = card?.startAt;
        const nextActivtyStartTime = nextCard?.startAt;
        const prevActivityEndTime = prevCard?.endAt;
        const lunchStartTimeOffset = Math.round((getDurationTime(`${colStartTime}:00`, card?.startAt) * 60) / 15);
        const isLunchDuringActivity = new Date(`October 01, 1998 ${currentActivityStartTime}`).getTime() < new Date(`October 01, 1998 ${prevActivityEndTime}`).getTime();
        const timeSectionsBetweenEndings = Math.round((getDurationTime(currentActivityEndTime, prevActivityEndTime) * 60) / 15);
        const freeTimeDuration = prevActivityEndTime === nextActivtyStartTime
          ? 0
          : getDurationTime(currentActivityEndTime, nextActivtyStartTime);

        if (isLunch && isLunchDuringActivity && timeSectionsBetweenEndings < 1) {
          card.duration = card.duration - sectionDuration;
        }

        if (!currentDuration) {
          return null;
        }

        return (
          <Fragment key={card?.id}>
            <ItineraryCard key={index} {...card} currency={currency} isLunchDuringActivity={isLunchDuringActivity} lunchStartTimeOffset={lunchStartTimeOffset} />
            {!Number.isNaN(freeTimeDuration) && freeTimeDuration > 0 && <ItineraryCard duration={freeTimeDuration} />}
          </Fragment>
        );
      })}
      <AccomodationCard
        size="auto"
        isChange={changeToday}
        title={accommodationCities[1]?.city?.name}
        time={lastActivityTime}
      />
    </div>
  );
};

export default ItineraryColumn;
