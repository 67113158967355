import moment from 'moment';
import React from 'react';

import { ScheduleDayType } from '../../constans/itineraries';

import styles from './index.module.scss';

const ItineraryHeader = ({ days = [], page = 0, perPage }) => {
  while (days?.length < perPage) {
    days.push('');
  }

  return (
    <div className={styles.header}>
      <div />
      {days.map((day, index) => {
        const isFreeDay = !day?.schedules?.length;

        return (
          <div className={styles.headerCell} key={index}>
            {!!day?.date && (
              <>
                <div className={styles.headerLabel}>
                  {`Day ${(index + 1) + (page * perPage)}`}
                  {!isFreeDay && <span className={styles.headerType}>{ScheduleDayType[day?.type]}</span>}
                </div>
                <div className={styles.headerDate}>{moment(day?.date).format('ddd, DD MMMM')}</div>
              </>
            )}
          </div>
        );
      })}
    </div>
  );
};

export default ItineraryHeader;
