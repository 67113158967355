import React from 'react';
import Modal from '../UI/Modal/Modal';
import styles from './ModalCTA.module.scss';

function ModalCTA({
  isShown, toggleModal, content, title, description, mainClass,
}) {
  return (
    <Modal show={isShown} displayToggle={toggleModal} modalClasses={`${styles.modal} ${mainClass}`}>
      <div>
        <h5 className={styles.title}>
          {title}
        </h5>
        <p className={styles.description}>{description}</p>
        <div className={styles.content}>
          {content}
        </div>
      </div>
    </Modal>
  );
}

ModalCTA.defaultProps = {
  toggleModal: null,
};

export default ModalCTA;
