import React from 'react';
import CommonCard from './Common';
import styles from './index.module.scss';

const LunchCard = ({
  size,
  isShortDuration,
  duration,
  lunchStartTimeOffset,
  isDuringActivity,
}) => {
  const title = 'Lunch break';

  return (
    <CommonCard
      className={`${styles.cardLunch} ${isDuringActivity && styles.overlaps}`}
      isShortDuration={isShortDuration}
      size={size}
      offset={lunchStartTimeOffset}
    >
      <div className={styles.cardSubTitle} data-is-short={isShortDuration} title={title}>{title}</div>
      <div className={`${styles.cardTime} mt-auto mb-0`}>{duration}</div>
    </CommonCard>
  );
};

export default LunchCard;
