import React from 'react';

import {
  LunchCard,
  TransportCard,
  ActivityCard,
  FreeTimeCard,
} from '../ItineraryCards';
import { ItineraryTypes } from '../../constans/itineraries';
import { decimalToHourPipe } from '../../helpers/helper-methods';

// todo: find a better way to implement
const ItineraryCard = ({
  id,
  type,
  duration,
  activity,
  startAt,
  price,
  currency,
  isLunchDuringActivity,
  lunchStartTimeOffset,
}) => {
  const activityDuration = Math.round((duration * 60) / 15);
  const isShortDuration = activityDuration <= 1;

  const commonData = {
    currency,
    isShortDuration,
    duration: decimalToHourPipe(duration),
    size: activityDuration,
  };

  if (!activityDuration) {
    return null;
  }

  switch (type) {
    case ItineraryTypes.Transport:
      return (
        <TransportCard {...commonData} />
      );

    case ItineraryTypes.Lunch:
      return (
        <LunchCard
          {...commonData}
          isDuringActivity={isLunchDuringActivity}
          lunchStartTimeOffset={lunchStartTimeOffset}
        />
      );

    case ItineraryTypes.Activity:
      return (
        <ActivityCard
          {...commonData}
          price={price}
          activity={activity}
          startTime={startAt}
          id={id}
        />
      );

    default:
      return (
        <FreeTimeCard {...commonData} />
      );
  }
};

export default ItineraryCard;
