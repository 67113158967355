import React, {
  useEffect,
  useMemo,
  useRef,
  useState,
  useCallback,
} from 'react';
import { navigate } from 'gatsby';
import moment from 'moment';
import { useInView } from 'react-intersection-observer';

import itinerarySearchService from '../../services/itinerarySearchService';
import ItineraryTable from '../../components/ItineraryTable';
import Layout from '../../components/Layout/Layout';
import Spinner from '../../components/UI/Spinner/Spinner';
import ItineraryPagination from '../../components/ItineraryPagination';
import { ItineraryTypes } from '../../constans/itineraries';
import {
  getQueryParams,
  chunk,
  formatCurrency,
} from '../../helpers/helper-methods';
import { useWindowWidth } from '../../hooks/useWindowWidth';
import {
  PHONE_WIDTH_BREAKPOINT,
  TABLET_LANDSCAPE_WIDTH_BREAKPOINT,
} from '../../constans/header';
import { RESULTS_FORMAT } from '../../constans/formats';
// import IconHighlights from '../../assets/img/icons/ic_highlights.svg';
import IconSlideshow from '../../assets/img/icons/ic_slideshow.svg';
import Slideshow from '../../components/Slideshow/Slideshow';
import Button from '../../components/UI/Button/Button';
import CookiesService from '../../services/cookie.service';
import { COOKIES_KEYS, LOCALSTORAGE_KEYS } from '../../constans/localstorage';
import StripePaymentPdfModal from '../../components/StripePaymentPdfModal/StripePaymentPdfModal';
import DocumentPdfService from '../../services/documentPdf.service';
import { COMPANY_TYPES, DOCUMENT_TYPES } from '../../constans/pdf-documents';

import styles from './index.module.scss';
import useDownloadItinerariesPdf from '../../hooks/useDownloadItinerariesPdf';
import ModalSavePackAgent, {
  deliveryVariants,
} from '../../components/UI/ModalSavePackAgent/ModalSavePackAgent';
import StorageService from '../../services/storage.service';
import FileSaverService from '../../services/file-saver.service';
import { ALGO_TIMEOUT, MODAL_TIMEOUT_TIME } from '../../constans/search';
import { isAuthGuard } from '../../guards/isAuthGuard';
import ModalCTA from '../../components/ModalCTA/ModalCTA';
import SignUpModal from '../../components/AutorizationModal/SignUpModal';
import { dayInMilliSeconds } from '../../constans/datepicker';
import UsersService from '../../services/usersService';
import StaysTab from '../../components/Home/StaysTab/StaysTab';
import { getRandomString } from '../../helpers/getRandomMessage';
import { STORAGE_DESTINATION } from '../../components/DestinationTree';
import destinationsService from '../../services/destinationsService';

// const SHOW_LIMIT = 11;
const PROPERTIES_COUNT = 4;

const HIGHLIGHT_ELEMENTS = {
  TOP_7: 7,
  TOP_10: 10,
  TOP_12: 12,
};
const HIGHLIGHT_LIMIT = {
  14: 14,
  24: 24,
};

const stripeFormDefault = {
  amount: 0,
  client_secret: '',
};

const ctaText = {
  title: 'Itinerary Pack',
  description:
    'Want to save this itinerary and the reviews of all activities it contains so you can use it as your personalized guidebook when you travel?',
};

const cta2Text = {
  title: 'Find Hotel',
  description: 'Now, let’s look for the best hotel offers',
};

const signUpDescription =
  'Please sign-up before we process your payment.<br>The link to the purchased document will be sent to your registered email address';

const storageService = StorageService();

function Itineraries({ location }) {
  const [isLoading, setIsLoading] = useState(true);
  const [isShowSlideshow, setIsShowSlideshow] = useState(false);
  const [isShowHighlights, setIsShowHighlights] = useState(false);
  const [data, setData] = useState([]);
  const [results, setResults] = useState([]);
  const [activities, setActivities] = useState([]);
  const [propertiesCount, setPropertiesCount] = useState(0);
  const [highlights, setHighlights] = useState([]);
  const [paginationState, setPaginationState] = useState();
  const [activePage, setActivePage] = useState(0);
  const [isEmpty, setIsEmpty] = useState(false);
  const [dates, setDates] = useState([]);
  const [totalCost, setTotalCost] = useState();
  const timeoutRef = useRef();

  const [stripePaymentModal, setStripePaymentModal] = useState(false);
  const [isModalSavePack, setIsModalSavePack] = useState(false);
  const [stripeFormData, setStripeFormData] = useState(stripeFormDefault);
  const documentPdfService = useMemo(() => DocumentPdfService(), []);
  const {
    downloadItinerariesPdf,
    downloadPdfLoading,
    link,
  } = useDownloadItinerariesPdf();
  const [isScheduleOnly, setIsScheduleOnly] = useState(false);

  const [itineraryExtract, setItinararyExtract] = useState(null);
  const [isModalShown, setIsModalShown] = useState(false);

  const [isBuyModalShown, setIsBuyModalShown] = useState(false);

  const [isFirstModalShown, setIsFirstModalShown] = useState(false);

  const [isShownSelectHotelWindow, setIsShownSelectHotelWindow] = useState(
    false
  );

  const [signUpModalCount, setSignUpModalCount] = useState(0);
  const [buyItineraryCount, setBuyItineraryCount] = useState(0);

  const storageDestination = StorageService().get(STORAGE_DESTINATION);
  const { region, city, district, country } = storageDestination;

  useEffect(() => {
    const propertiesModel = {
      countryId: country?.id,
      regionId: region?.id,
      cityId: city?.id,
      districtId: district?.id,
      offset: 10,
      limit: 10,
    };

    destinationsService()
      .getDestinationProperties({
        ...propertiesModel,
      })
      .then((response) => setPropertiesCount(response?.pagination.totalCount));
  }, []);

  useEffect(() => {
    const modalTimer = setTimeout(() => {
      setIsModalShown(true);
    }, MODAL_TIMEOUT_TIME + ALGO_TIMEOUT);

    return () => {
      clearTimeout(modalTimer);
    };
  }, []);

  useEffect(() => {
    if (signUpModalCount === 1) {
      const modalTimer = setTimeout(() => {
        setIsBuyModalShown(true);
      }, MODAL_TIMEOUT_TIME);

      return () => {
        clearTimeout(modalTimer);
      };
    }
  }, [signUpModalCount]);

  useEffect(() => {
    if (buyItineraryCount === 1) {
      const modalTimer = setTimeout(() => {
        setIsShownSelectHotelWindow(true);
      }, MODAL_TIMEOUT_TIME);

      return () => {
        clearTimeout(modalTimer);
      };
    }
  }, [buyItineraryCount]);

  const isAuth = isAuthGuard();

  const { search } = location;
  const { uuid, adults = 1, kids = 0, currency, locationName } = getQueryParams(
    search
  );
  const windowWidth = useWindowWidth();
  const { ref: topPaginationRef, inView: topPaginationInView } = useInView({
    threshold: 0.3,
  });
  const {
    ref: bottomPaginationRef,
    inView: bottomPaginationInView,
  } = useInView({
    threshold: 0.6,
  });

  const isMobile = useMemo(() => windowWidth <= PHONE_WIDTH_BREAKPOINT, [
    windowWidth,
  ]);
  const isTablet = useMemo(
    () => windowWidth <= TABLET_LANDSCAPE_WIDTH_BREAKPOINT,
    [windowWidth]
  );
  const daysPerPage = isMobile ? 1 : isTablet ? 3 : 5;

  const partnerNickname = CookiesService().getCookie(
    COOKIES_KEYS.PARTNER_NICKNAME,
    ''
  );

  const getData = async () => {
    try {
      const {
        data: responseData = [],
        totalActivitiesCost,
        pagination,
      } = await itinerarySearchService().searchByUUID({
        uuid,
        partnerNickname,
      });

      const startDate = responseData?.[0]?.date;
      const endDate = responseData?.[responseData?.length - 1]?.date;

      if (responseData?.every(({ schedules }) => !schedules?.length)) {
        setIsEmpty(true);
      }

      setResults(responseData);
      setDates([startDate, endDate]);
      setPaginationState(pagination);
      setTotalCost(totalActivitiesCost);
      setTimeout(() => setIsLoading(false), 100);
    } catch {
      const timeout = setTimeout(() => getData(), 3000);
      timeoutRef.current = timeout;
    }
  };

  useEffect(() => {
    getData();

    return () => {
      clearTimeout(timeoutRef?.current);
    };
  }, []);

  const toggleModalSlideShow = () => {
    setIsShowSlideshow((prev) => !prev);
  };

  const toggleModalHighlights = () => {
    setIsShowHighlights((prev) => !prev);
  };

  const getHighlights = useCallback((activities) => {
    if (!activities.length) return;
    const operationList = [...activities];
    const sortedActivities = operationList.sort(
      (activityA, activityB) => activityB.PV - activityA.PV
    );
    const slicedActivities = [...sortedActivities];

    if (activities.length <= HIGHLIGHT_LIMIT[14]) {
      const elementToDelete = activities.length - HIGHLIGHT_ELEMENTS.TOP_7;
      slicedActivities.splice(HIGHLIGHT_ELEMENTS.TOP_7, elementToDelete);
    } else if (
      activities.length > HIGHLIGHT_LIMIT[14] &&
      activities.length <= HIGHLIGHT_LIMIT[24]
    ) {
      const elementToDelete = activities.length - HIGHLIGHT_ELEMENTS.TOP_10;
      slicedActivities.splice(HIGHLIGHT_ELEMENTS.TOP_10, elementToDelete);
    } else {
      const elementToDelete = activities.length - HIGHLIGHT_ELEMENTS.TOP_12;
      slicedActivities.splice(HIGHLIGHT_ELEMENTS.TOP_12, elementToDelete);
    }

    setHighlights(slicedActivities);
  }, []);

  const getActivities = useCallback(
    (results) => {
      if (!results.length) return;
      const activities = [];
      results.map((result) => {
        if (!result.schedules.length) return;
        result.schedules.map((schedule) => {
          if (schedule.type === ItineraryTypes.Activity) {
            activities.push(schedule.activity);
          }
        });
      });
      setActivities(activities);
    },
    [setActivities]
  );

  useEffect(() => {
    setData(chunk(results, daysPerPage));
    getActivities(results);
  }, [results, isMobile, isTablet]);

  useEffect(() => {
    if (activities?.length) {
      getHighlights(activities);
    }
  }, [activities]);

  const handleDownloadPdf = useCallback(
    (email, agent, deliveryOption) => {
      downloadItinerariesPdf(
        {
          uuid,
          locationName,
          currency,
          email,
          partnerNickname,
          deliveryOption,
          isScheduleOnly,
        },
        {
          ...agent,
        }
      );
    },
    [partnerNickname, currency, locationName, uuid, isScheduleOnly]
  );

  const stripeSuccessCallback = useCallback(
    (email) => {
      if (email) {
        const deliveryOption = deliveryVariants.EMAIL;
        const isScheduleOnly = false;

        downloadItinerariesPdf(
          {
            uuid,
            locationName,
            currency,
            email,
            partnerNickname,
            deliveryOption,
            isScheduleOnly,
          },
          {}
        );

        setStripePaymentModal(false);
      }
    },
    [uuid, locationName, currency, partnerNickname, downloadItinerariesPdf]
  );

  const toggleStripePaymentModal = () => {
    setStripePaymentModal((prevState) => !prevState);
    if (!isAuth) {
      setIsModalShown(true);
    }
  };
  const toggleModalSavePack = () =>
    setIsModalSavePack((prevState) => !prevState);

  const getClientSecretAndAmountDocument = useCallback(() => {
    documentPdfService
      .getItineraryPackAmountAndSecretKey(uuid, partnerNickname)
      .then(({ data }) => {
        setStripeFormData(data);
      });
  }, [documentPdfService, setStripeFormData, uuid, partnerNickname]);

  const isBPAgent = useMemo(() => {
    const user = StorageService().get(LOCALSTORAGE_KEYS.USER, {});
    return user?.type === COMPANY_TYPES.b2b;
  }, []);

  const getItineraryExtract = useCallback(() => {
    FileSaverService()
      .getDocumentByDocumentType(DOCUMENT_TYPES.itineraryExtract)
      .then(({ data }) => {
        setItinararyExtract(data);
      });
  }, [setItinararyExtract]);

  useEffect(() => {
    if (link) {
      if (typeof window !== 'undefined') {
        window.open(link, '_blank', 'noopener noreferrer');
      }
    }
  }, [link]);

  useEffect(() => {
    getItineraryExtract();
  }, []);

  useEffect(() => {
    if (isAuth) {
      const modalTimer = setTimeout(() => {
        setIsBuyModalShown(true);
      }, MODAL_TIMEOUT_TIME + ALGO_TIMEOUT);

      return () => {
        clearTimeout(modalTimer);
      };
    }
  }, []);

  let urlParams = {};

  if (typeof window !== 'undefined') {
    const params = new URLSearchParams(window.location.search.slice(1));
    urlParams = Object.fromEntries(params);
    if (!urlParams.checkin) {
      urlParams.checkin = new Date(Date.now() + 7 * dayInMilliSeconds);
    }
    if (!urlParams.checkout) {
      urlParams.checkout = new Date(Date.now() + 9 * dayInMilliSeconds);
    }
  }

  const handleProfileChanges = async (values) => {
    const isAuth = isAuthGuard();

    if (!isAuth) {
      storageService.set(LOCALSTORAGE_KEYS.USER, {
        ...getUser(),
        searchProfile: values,
      });
      return;
    }

    const { data } = await UsersService().updateSearchProfile(values);

    const {
      // eslint-disable-next-line no-unused-vars
      id,
      createdAt,
      updatedAt,
      ...searchProfile
    } = data;

    storageService.set(LOCALSTORAGE_KEYS.USER, { ...getUser(), searchProfile });
  };

  const paymentFunction = () => {
    getClientSecretAndAmountDocument();
    toggleStripePaymentModal();
    setStripePaymentModal(true);
  };

  if (isEmpty) {
    return (
      <Layout mainClasses="column-flexed-wrapper">
        <div className="container">
          <div
            className={`flex flex-v-center flex-wrap ${styles.searchCrumbWrapper}`}
          >
            <div className={styles.searchCrumb}>
              {`${moment(dates[0]).format(RESULTS_FORMAT)} - ${moment(
                dates[1]
              ).format(RESULTS_FORMAT)}`}
            </div>
            <div className={styles.dot} />
            <div className={styles.searchCrumb}>
              {`${adults}A ${kids ? `+ ${kids}K` : ''}`}
            </div>
          </div>
          <div className={`row col ${styles.title}`}>
            {`${locationName} itinerary for ${paginationState?.totalCount} ${
              paginationState?.totalCount > 1 ? 'days' : 'day'
            }`}
          </div>
        </div>

        <div className="flex flex-grow-1 flex-h-start">
          <div className={`container my-auto ${styles.page}`}>
            <p className={`${styles.nothingFoundText} mb-0`}>
              There is no Itinerary for your search inputs.
            </p>
            <p className={styles.nothingFoundText}>
              We suggest you amend some of the selected options and filters
              and/or expand your search criteria.
            </p>
            <button
              onClick={() => navigate('/')}
              type="button"
              className={`
               ${styles.nothingFoundButton}
               blocked btn btn_mld mx-auto btn_inverted btn-uppercased btn_bordered btn_radius-28 t-700
             `}
            >
              Back to Search
            </button>
          </div>
        </div>
      </Layout>
    );
  }

  if (isLoading) {
    return (
      <Layout>
        <div className="container">
          <div className={styles.wrapper}>
            <Spinner spinnerClasses="spinner-primary" />
            <div className={styles.fadeInText}>
              <h1 className={styles.randomText}>{getRandomString()}</h1>
            </div>
          </div>
        </div>
      </Layout>
    );
  }

  return (
    <Layout>
      <div className="container">
        {isModalSavePack && (
          <ModalSavePackAgent
            isShow={isModalSavePack}
            toggleModal={toggleModalSavePack}
            title={isScheduleOnly ? 'Export itinerary' : 'Save itinerary pack'}
            isItineraries
            locationInfo={`${locationName} itinerary for ${
              paginationState?.totalCount
            } ${paginationState?.totalCount > 1 ? 'days' : 'day'}`}
            isDownloading={downloadPdfLoading}
            onDownloadPdf={handleDownloadPdf}
          />
        )}
        <ModalCTA
          isShown={isBuyModalShown && !isShowSlideshow}
          toggleModal={() => {
            setIsBuyModalShown();
            setBuyItineraryCount((prevCount) => prevCount + 1);
          }}
          title={ctaText.title}
          description={ctaText.description}
          mainClass={styles.buyModalWindow}
          content={
            <div>
              <Button
                classes={`t-600 btn btn-uppercased btn_common ${styles.buyPack}`}
                onClick={() => {
                  window.dataLayer = window.dataLayer || [];
                  window.dataLayer.push({
                    event: 'onClickBuyItineraryPack',
                  });

                  setIsFirstModalShown(true);

                  if (isBPAgent) {
                    setIsScheduleOnly(false);
                    toggleModalSavePack();
                  } else {
                    getClientSecretAndAmountDocument();
                    toggleStripePaymentModal();
                  }
                }}
              >
                <div className={`flex flex-v-center ${styles.tooltipBlock}`}>
                  Buy Itinerary Pack
                </div>
              </Button>
            </div>
          }
        />
        <ModalCTA
          isShown={
            propertiesCount > PROPERTIES_COUNT &&
            !isShowSlideshow &&
            isShownSelectHotelWindow &&
            !stripePaymentModal
          }
          toggleModal={() => setIsShownSelectHotelWindow()}
          title={cta2Text.title}
          description={cta2Text.description}
          mainClass={`${styles.container} ${styles.staysModal}`}
          content={
            <div className={styles.selectHotel}>
              <StaysTab
                handleSubmitForm={handleProfileChanges}
                initValues={urlParams}
                isModal
                destinationModalClasses={styles.destinationModalClasses}
                cardClass={styles.cardModal}
              />
            </div>
          }
        />
        {!!stripeFormData.client_secret.length && (
          <StripePaymentPdfModal
            displayToggle={toggleStripePaymentModal}
            show={stripePaymentModal && isAuth}
            stripeSubmitCallback={stripeSuccessCallback}
            clientSecret={stripeFormData.client_secret}
            amount={stripeFormData.amount}
            locationName={locationName}
            type={DOCUMENT_TYPES.itineraryPack}
            uuid={uuid}
            currency={currency}
            totalCount={paginationState.totalCount}
            locationInfo={`${locationName} itinerary for ${
              paginationState?.totalCount
            } ${paginationState?.totalCount > 1 ? 'days' : 'day'}`}
          />
        )}
        {isShowSlideshow && (
          <Slideshow
            isOpen={isShowSlideshow}
            onCloseClick={toggleModalSlideShow}
            slides={activities}
          />
        )}
        {isShowHighlights && (
          <Slideshow
            isOpen={isShowHighlights}
            onCloseClick={toggleModalHighlights}
            slides={highlights}
          />
        )}
        <div
          className={`flex flex-v-center flex-wrap ${styles.searchCrumbWrapper}`}
        >
          <div className={styles.searchCrumb}>
            {`${moment(dates[0]).format(RESULTS_FORMAT)} - ${moment(
              dates[1]
            ).format(RESULTS_FORMAT)}`}
          </div>
          <div className={styles.dot} />
          <div className={styles.searchCrumb}>
            {`${adults}A ${kids ? `+ ${kids}K` : ''}`}
          </div>
        </div>
        <div className={`row col ${styles.title}`}>
          {`${locationName} itinerary for ${paginationState?.totalCount} ${
            paginationState?.totalCount > 1 ? 'days' : 'day'
          }`}
        </div>
        <div className="flex flex-between flex-v-end flex-wrap">
          <div className={`${styles.buttonContainer}`}>
            <div className={`flex ${styles.actionBlock}`}>
              {/* activities?.length >= SHOW_LIMIT
             && (
             <div className={`${styles.highlights} ${styles.firstButton}`}>
               <button
                 type="button"
                 className={`btn btn_inverted_yellow btn-uppercased t-600 ${styles.highlights}`}
                 onClick={() => {
                   setIsShowHighlights(true);
                 }}
               >
                 <div className={styles.buttonContent}>
                   <IconHighlights className={styles.icon} />
                   <div>Highlights</div>
                 </div>
               </button>
             </div>
             ) */}
              {propertiesCount > PROPERTIES_COUNT && (
                <div className={`${styles.highlights}`}>
                  <button
                    type="button"
                    className={`btn btn_inverted_yellow btn-uppercased t-600 ${styles.highlights}`}
                    onClick={() => {
                      setIsShownSelectHotelWindow(true);
                      window.dataLayer = window.dataLayer || [];
                      window.dataLayer.push({
                        event: 'onClickSelectHotel',
                      });
                    }}
                  >
                    <div className={styles.buttonContent}>
                      <div>Select Hotels</div>
                    </div>
                  </button>
                </div>
              )}
              <div className={`${styles.buyPackBlock}`}>
                <Button
                  classes={`t-600 btn btn-uppercased btn_common ${styles.buyPack}`}
                  onClick={() => {
                    window.dataLayer = window.dataLayer || [];
                    window.dataLayer.push({
                      event: 'onClickBuyItineraryPack',
                    });
                    setIsFirstModalShown(true);
                    if (isBPAgent) {
                      setIsScheduleOnly(false);
                      toggleModalSavePack();
                    } else {
                      getClientSecretAndAmountDocument();
                      toggleStripePaymentModal();
                    }
                  }}
                >
                  <div className={`flex flex-v-center ${styles.tooltipBlock}`}>
                    Buy Itinerary Pack
                  </div>
                </Button>
                {itineraryExtract?.link && (
                  <a
                    href={itineraryExtract.link}
                    target="_blank"
                    rel="noreferrer"
                  >
                    See example
                  </a>
                )}
              </div>

              <div className={`${styles.highlights}`}>
                <button
                  type="button"
                  className={`btn btn_inverted_yellow btn-uppercased t-600 ${styles.highlights}`}
                  onClick={() => {
                    setIsShowSlideshow(true);
                  }}
                >
                  <div className={styles.buttonContent}>
                    <IconSlideshow className={styles.icon} />
                    <div>Show</div>
                  </div>
                </button>
              </div>

              {isBPAgent && (
                <div className={`${styles.exportPackBlock}`}>
                  <Button
                    classes={`t-600 btn btn-uppercased btn_common ${styles.buyPack}`}
                    onClick={() => {
                      if (isBPAgent) {
                        setIsScheduleOnly(true);
                        toggleModalSavePack();
                      }
                    }}
                  >
                    <div
                      className={`flex flex-v-center ${styles.tooltipBlock}`}
                    >
                      Export itinerary
                    </div>
                  </Button>
                </div>
              )}
            </div>
          </div>
          <ItineraryPagination
            setActivePage={setActivePage}
            activePage={activePage}
            totalPages={data?.length}
            ref={topPaginationRef}
          />
        </div>
        <div
          className={`${styles.flyBtn} ${
            (topPaginationInView || bottomPaginationInView) && styles.hidden
          }`}
        >
          <ItineraryPagination
            isBottom
            setActivePage={setActivePage}
            activePage={activePage}
            totalPages={data?.length}
            className="m-0"
          >
            <p className={`text-center ${styles.dayIndex}`}>
              {`Day ${activePage + 1}`}
            </p>
          </ItineraryPagination>
        </div>
        <ItineraryTable
          currency={currency}
          perPage={daysPerPage}
          page={activePage}
          data={data[activePage]}
        />
        <ItineraryPagination
          isBottom
          ref={bottomPaginationRef}
          setActivePage={setActivePage}
          activePage={activePage}
          totalPages={data?.length}
        >
          <p className={`text-center ${styles.dayIndex}`}>
            {`Day ${activePage + 1}`}
          </p>
        </ItineraryPagination>
        <div className={styles.total}>
          {`Total Activities cost: ${
            totalCost ? formatCurrency(currency, totalCost) : 'Free'
          }`}
        </div>
      </div>
      <SignUpModal
        isShown={!isAuth && isModalShown}
        toggleModal={() => {
          setIsModalShown();
          setSignUpModalCount((prevCount) => prevCount + 1);
        }}
        isSignIn={false}
        subTitle={isFirstModalShown && signUpDescription}
        withOutRedirect={false}
        paymentFunction={paymentFunction}
      />
    </Layout>
  );
}

export default Itineraries;
