/* eslint-disable max-len */
import React from 'react';
import moment from 'moment';

import ItineraryColumn from '../ItineraryColum';
import ItineraryHeader from '../ItineraryHeader';
import { getHoursFromTimeString } from '../../helpers/helper-methods';

import styles from './index.module.scss';

const ItineraryTable = ({
  data,
  page,
  perPage,
  currency,
}) => {
  const getTimestamp = (hourIndex) => moment({ hour: hourIndex % 24 }).format('HH:00');

  const earliestStartTime = data
    ?.map(({ schedules = [] }) => schedules[0]?.startAt)
    .reduce((acc, item) => (getHoursFromTimeString(item) < acc ? getHoursFromTimeString(item) : acc), 9) - 1;

  const colStartTime = getTimestamp(earliestStartTime);

  const endTime = data.reduce((acc, { schedules }) => {
    const endTimeHour = parseInt(schedules?.[schedules?.length - 1]?.endAt, 10);
    return acc < endTimeHour ? endTimeHour : acc;
  }, 9);

  while (data.length < perPage) {
    data.push({});
  }

  return (
    <div className={styles.itinerary}>
      <ItineraryHeader days={data} page={page} perPage={perPage} />
      <div className="flex">
        <div className={styles.timing}>
          {Array((endTime - earliestStartTime) + 2) // add extra space for `Return accommodation`
            .fill('')
            .map((_, index) => (
              <div key={index}>
                {getTimestamp(index + earliestStartTime)}
              </div>
            ))}
        </div>
        <div className={styles.table}>
          {data?.map((day, index) => (
            <ItineraryColumn
              key={index}
              currency={currency}
              colStartTime={colStartTime}
              lastActivityTime={day?.schedules?.[day?.schedules?.length - 1]?.endAt}
              accommodationCities={day.accommodationCities}
              cards={day.schedules?.filter(({ duration }) => !!duration)}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default ItineraryTable;
