import React from 'react';
import CommonCard from './Common';
import styles from './index.module.scss';

const FreeTimeCard = ({ isShortDuration, size }) => (
  <CommonCard
    className={styles.cardAccomodation}
    isShortDuration={isShortDuration}
    size={size}
  >
    <div data-is-short={isShortDuration} className={styles.cardTitle}>Free time</div>
  </CommonCard>
);

export default FreeTimeCard;
